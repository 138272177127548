var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:("" + ('ma-1 m-shadow cursor-card ' + _vm.clase)),attrs:{"id":("" + ('zone-' + _vm.item_sync.id)),"outlined":""}},[(_vm.cargando)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.color_cargando}})],1):_c('div',[_c('v-card-text',{staticClass:"mt-0 mb-0 pt-1 pl-1 pr-1 pb-0",on:{"click":function($event){_vm.showFicha = true}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-center"},'label',attrs,false),on),[_vm._v(_vm._s(_vm.item_sync.nombre)+" "),(_vm.item_sync.id_tipo_dato === 3)?[_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v("["+_vm._s(_vm.item_sync.cantidad)+"g.]")])]:_vm._e()],2)]}}])},[_c('span',[_vm._v(_vm._s(_vm.item_sync.nombre))])])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card-actions',{staticClass:"mt-0 pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","icon":"","fab":"","x-small":""},on:{"click":function($event){_vm.showDelete = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,3400740735)},[_c('span',[_vm._v("Eliminar "+_vm._s(_vm.name_ficha))])]),_c('v-menu',{staticClass:"m-shadow",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","icon":"","fab":"","x-small":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v("Opciones "+_vm._s(_vm.name_ficha))])])]}}],null,false,1598593628)},[_c('v-toolbar',{class:_vm.color_item},[_vm._v(_vm._s(_vm.item_sync.nombre))]),_c('v-list',_vm._l((_vm.opciones),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handlerEvents(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icono))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","fab":"","x-small":""},on:{"click":function($event){return _vm.$emit('copy')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("content_copy")])],1)]}}],null,false,2838728196)},[_c('span',[_vm._v("Copiar "+_vm._s(_vm.name_ficha))])])],1):_vm._e()],1),(_vm.showDelete)?_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.showDelete),callback:function ($$v) {_vm.showDelete=$$v},expression:"showDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" ¿Seguro que desea eliminar el "+_vm._s(_vm.name_ficha)+" "),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.item_sync.nombre))]),_vm._v("? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-4","text":""},on:{"click":function($event){return _vm.sendDelete()}}},[_vm._v("Sí")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showDelete = false}}},[_c('strong',[_vm._v("No")])])],1)],1)],1):_vm._e(),(_vm.showFicha)?_c('dialogdatosplato',{attrs:{"dialog":_vm.showFicha,"item":_vm.item_sync},on:{"update:dialog":function($event){_vm.showFicha=$event},"update:item":function($event){_vm.item_sync=$event}}}):_vm._e(),(_vm.showEquivalentes)?_c('dialog_select_equivalente',{attrs:{"dialog":_vm.showEquivalentes,"item":_vm.item_sync,"equivalentes":_vm.equivalentes,"id_paciente":_vm.id_paciente},on:{"update:dialog":function($event){_vm.showEquivalentes=$event},"update:item":function($event){_vm.item_sync=$event},"update:equivalentes":function($event){_vm.equivalentes=$event},"update:id_paciente":function($event){_vm.id_paciente=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }