


































































































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import { dieta_visualizacionExtendsDataDto } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacionExtendsDataDto";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
import { EstructuraBasicaGeneracionAutomaticaDieta } from "@/shared/dtos/visualizacion_dieta/EstructuraBasicaGeneracionAutomaticaDieta";
@Component({
  components: {
    dialogdatosplato: () => import("./dialog_datos_plato.vue"),
    dialog_select_equivalente: () => import("./dialog_select_equivalente.vue"),
  },
})
export default class CellInfoTemplate extends Vue {
  @PropSync("item", {
    type: Object,
    default: () => {},
  })
  item_sync!: dieta_visualizacion;
  @PropSync("id_paciente") id_pacientesync!: number;
  public showDelete: boolean = false;
  public showFicha: boolean = false;
  public showEquivalentes: boolean = false;
  public equivalentes: EstructuraBasicaGeneracionAutomaticaDieta[] = [];

  public opciones = [
    { title: "Clonar", id: 1, icono: "repeat_one" },
    { title: "Ver ficha", id: 2, icono: "remove_red_eye" },
    { title: "Cambiar por equivalente", id: 3, icono: "repeat" },
  ];

  public cargando = false;

  public created() {}

  public get clase() {
    switch (this.item_sync.id_tipo_dato) {
      case 1:
        return "et_pb_blurb_plato";
        break;
      case 2:
        return "et_pb_blurb_suplemento";
        break;
      case 3:
        return "et_pb_blurb_alimento";
        break;
    }
  }

  /**
   * AddNogusta
   */
  public AddNogusta() {
    if (this.opciones.filter((x) => x.id === 4).length === 0) {
      this.opciones.push({
        title: "Añadir a gustos no deseados",
        id: 4,
        icono: "playlist_add",
      });
    }
  }

  public get name_ficha() {
    switch (this.item_sync.id_tipo_dato) {
      case 1:
        this.AddNogusta();
        return "plato";
        break;
      case 2:
        return "suplemento";
        break;
      case 3:
        this.AddNogusta();
        return "alimento";
        break;
    }
  }
  public sendDelete() {
    this.showDelete = false;
    this.$emit("delete");
  }

  public handlerEvents(item: any) {
    switch (item.id) {
      case 1:
        this.$emit("clone");
        break;
      case 2:
        this.showFicha = true;
        break;
      case 3:
        dieta_visualizacionModule
          .equivalenteplato_dieta(this.item_sync)
          .then((x: EstructuraBasicaGeneracionAutomaticaDieta[]) => {
            this.equivalentes = x;
            this.showEquivalentes = true;
          });

        /*.then((x: dieta_visualizacion) => {
            this.item_sync.id_estructura = x.id_estructura;
            this.item_sync.nombre = x.nombre;
          });*/
        break;
      case 4:
        this.cargando = true;
        var id_estructura_anterior = new dieta_visualizacion(this.item_sync);
        dieta_visualizacionModule
          .add_gustosplato_dieta(this.item_sync)
          .then((xk: dieta_visualizacionExtendsDataDto) => {
            this.item_sync.id_estructura = xk.id_estructura;
            this.item_sync.nombre = xk.nombre;
            this.cargando = false;
            if (xk.hay_mas) {
              import("@/shared/services/message-service").then((x) => {
                x.ssmMessageService.confirm(
                  "Hemos visto que hay más platos de " +
                    id_estructura_anterior.nombre +
                    ", ¿le gustaría remplazarlos todos por " +
                    xk.nombre +
                    " ?",
                  "Hay más de " +
                    id_estructura_anterior.nombre +
                    " ¿Quiere cambiarlos todos?",
                  (res) => {
                    if (res) {
                      //x.ssmMessageService.alert("ok");
                      dieta_visualizacionModule.postremplazarplato({
                        id_estructura_new: xk.id_estructura,
                        plato: id_estructura_anterior,
                      });
                    }
                  }
                );
              });
            }
          })
          .finally(() => (this.cargando = false));

        break;
      default:
        break;
    }
  }

  public get color_item() {
    switch (this.item_sync.id_tipo_dato) {
      case 1:
        return "title_plato";
        break;
      case 2:
        return "title_suplemento";
        break;
      case 3:
        return "title_alimento";
        break;
    }
  }

  public get color_cargando() {
    switch (this.item_sync.id_tipo_dato) {
      case 1:
        return "#009587";
        break;
      case 2:
        return "#7bc5fe";
        break;
      case 3:
        return "#ebbe58";
        break;
    }
  }
}
